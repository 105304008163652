<template>
  <div id="forgot-password" class="mt-10">
    <auth-forms-wrapper height="">
      <template #default>
        <div id="login-form">
          <v-form
            ref="resetForm"
            id="forgot-password__form"
            v-model="isFormValid"
            @submit.prevent="onSubmitHandler"
            @keydown.enter="onSubmitHandler"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <h1 class="subtitle-1 text--primary font-weight-large pb-2">
                  {{ displayMessages.title }}
                </h1>

                <div>
                  <span class="text-lg-subtitle-1 subtitle-2 text--primary">
                    {{ displayMessages.subtitle }}
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" class="pt-6">
                <v-text-field
                  label="Email Address"
                  v-model="email"
                  outlined
                  class="required"
                  required
                  :rules="[required('Email Address'), validateEmail]"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="pt-1">
                <v-btn
                  type="submit"
                  :disabled="!isFormValid"
                  large
                  block
                  :loading="isLoading"
                  class="button font-weight-bold"
                  color="primary"
                >
                  Reset Password
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <div class="pt-6 text-center">
            <router-link class="text-decoration-none" :to="$paths.login">
              <strong class="subtitle-1 black--text"> Back to login</strong>
            </router-link>
          </div>
        </div>
      </template>
    </auth-forms-wrapper>
  </div>
</template>

<script>
import { required, email as validateEmail } from "@/validators";
import { AuthFormsWrapper } from "@/components/shared";
import { resetPasswordEmail } from "@/services";

/** Forgot password error messages */
const AUTH_ERRORS = ["auth/user-not-found"];

/**
 * Forgot Password view
 */
export default {
  name: "ForgotPassword",
  /**
   * ---------------- Components ----------------
   */
  components: {
    AuthFormsWrapper,
  },
  /**
   * ---------------- Data properties ----------------
   */
  data() {
    return {
      email: "",
      isFormValid: false,
      isLoading: false,
      errorMessage: "",
    };
  },
  computed: {
    /**
     * Error messages
     * @description Fetched from the app dictionary
     */
    displayMessages() {
      const { form } = this.$dictionary.app.forgotPassword;
      return { ...form };
    },
  },
  methods: {
    required,
    validateEmail,
    /**
     * Submits forgot password form and sends the reset password email to the user
     */
    async onSubmitHandler() {
      try {
        if (!this.isFormValid) return this.$refs.resetForm.validate();
        this.isLoading = true;
        // Sends reset password email
        await resetPasswordEmail(this.email);

        // Redirect user to reset password confirmation page
        this.$router.push({
          path: this.$paths.resetPasswordConfirmation,
          query: { email: this.email },
        });
      } catch (error) {
        this.exceptionHandler(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Handles the api errors and show user messages
     */
    exceptionHandler(error) {
      let { message, code } = error;
      const [USER_NOT_FOUND] = AUTH_ERRORS;

      if (code === USER_NOT_FOUND) {
        message = this.$dictionary.app.forgotPassword.form.invalidUserMsg;
      }
      this.errorMessage = message;
    },
  },
};
</script>
